$(document).ready(function () {
	//Altering time display to match Bryan Health's style guide 6-7:30 p.m.

	$(".EventTime").each(function () {
		replaceTime($(this));
	});
	$(".EventTimes").each(function () {
		replaceTime($(this));
	});
	$(".TimeCol").each(function () {
		replaceTime($(this));
	});
	$(".ResultsContainer").each(function () {
		replaceTime($(this));
	});
});

function replaceTime(el) {
	$all = el.html();

	//finds on-the-hour time (1:00) time and removes the :00
	$all = $all.replace(/(1[012]|[1-9])(:[0][0])+ ?([AaPp][mM])/g, "$1 $3");

	// remove space before and after the hyphen
	//$all = $all.replace(/ - /g, '&ndash;');

	// change all uppercase AM/PM to lowercase with dots
	$all = $all.replace(/AM/g, 'a.m.').replace(/PM/g,'p.m.');
	
	el.html($all);
}